<template>
	<v-container class="mt-4 ml-2" fluid>
	  <v-row>
	  	<v-col cols="12">
	  		<v-card class="shadowCard">
		      <v-card-title>
		        <span class="text-subtitle-1 font-weight-bold text-center">
		          Asignar Salones al grupo
		        </span>
		        <v-spacer></v-spacer>

		        <v-btn
		          color="primary"
		          dark
		          class="text-capitalize"
		          @click="initialize()"
		          small
		          tile
		        >
		          <v-icon left small>mdi-refresh</v-icon>
		          Actualizar
		        </v-btn>
		      </v-card-title>

		      <v-card-text>
		        <v-row>
		          <v-col cols="12" md="4" lg="4" class="pb-0">
		            <v-select
		              :items="planteles"
		              v-model="plantel"
		              label="Plantel"
		              filled
		              dense
		              clearable
		            ></v-select>
		          </v-col>

		          <v-col cols="12" md="4" lg="4" class="pb-0">
		            <v-select
		              :items="niveles"
		              v-model="nivel"
		              label="Nivel"
		              filled
		              dense
		              clearable
		            ></v-select>
		          </v-col>

		          <v-col cols="12" md="4" lg="4" class="pb-0">
		            <v-select
		              :items="horarios"
		              v-model="horario"
		              label="Horario"
		              filled
		              dense
		              clearable
		            ></v-select>
		          </v-col>

		          <v-col cols="12" md="4" lg="4" class="pb-0">
		            <v-select
		              :items="ciclos"
		              v-model="ciclo"
		              label="Ciclo"
		              filled
		              dense
		              clearable
		            ></v-select>
		          </v-col>

		          <v-col cols="12" md="8" lg="8" class="pb-0">
		            <v-text-field
		              v-model="search"
		              label="Buscar"
		              filled
		              append-icon="mdi-magnify"
		              dense
		              clearable
		            ></v-text-field>
		          </v-col>


		        </v-row>

		        <v-data-table
					    :headers="headers"
					    :items="filterGrupos"
					    class="elevation-0"
					    :search="search"
					    :mobile-breakpoint="100"
		          dense
					  >
					    <template v-slot:no-data>
					      <v-btn
					        color="primary"
					        @click="initialize"
					        small
					      >
					        Actualizar
					      </v-btn>
					    </template>

					    <template v-slot:item.salon="props">
				        <v-edit-dialog
				          :return-value.sync="props.item.salon"
				          @save="save( props.item )"
				          large
				        >
				          {{ props.item.salon }}
				          <template v-slot:input>
				            <v-autocomplete
		                  :items="salones"
		                  v-model="props.item.id_salon"
		                  label="Salone"
		                  dense
		                  filled
		                  item-value="id_salon"
		                  item-text="salon"
		                  class="ma-2"
		                  hide-details
		                ></v-autocomplete>
				          </template>
				        </v-edit-dialog>
				      </template>

					  </v-data-table>
		      </v-card-text>
		    </v-card>
	  	</v-col>
	  </v-row>

    <!-- Dialog de carga -->
    <Alerta               v-if="respuestaAlerta"          :parametros="parametros"/>
    <carga                v-if="cargar"/>


	</v-container>
</template>
<script>
import axios from 'axios';
	import { mapGetters } from 'vuex'
	// Componentes
  import Alerta              from '@/components/alertas/Alerta.vue'
  import carga               from '@/components/alertas/carga.vue';

  import validarErrores      from '@/mixins/validarErrores'

  import mixinLeads          from '@/mixins/mixinLeads'

	// Componentes
  export default {
  	components:{
      Alerta,
      carga,
    },

    mixins: [ validarErrores, mixinLeads ],

  	props:['dialogGrupos'],

    data: () => ({
			// Alertas
    	parametros:{
        dialogError: false,
        mensaje: '',
        color: ''
      },

      respuestaAlerta:false,
      loader: true,
      cargar: false,
      
    	// Datos del alumno inscrito
    	grupos:[],

    	planteles: [],
    	plantel  : null,
    	niveles  : [],
    	nivel    : null,
    	ciclos   : [],
    	ciclo    : null,
    	horarios : [],
    	horario  : null,

    	// tabla
			search:'',
      page: 1,
      pageCount: 0,
      itemsPerPage: 10,
      grupos: [],
      headers: [
        { text: 'ID'          , value: 'id_grupo'    },
        { text: 'Grupo'       , value: 'grupo'       },
        { text: 'Horario'     , value: 'horario'     },
        { text: 'Cupo'        , value: 'capacidad'   },
        { text: 'Nivel'       , value: 'nivel'       },
        { text: 'Inscritos'   , value: 'inscritos'   },
        { text: 'Salon'       , value: 'salon'       },
        { text: 'Disponibles' , value: 'disponibles' },
      ],

      salones: [],

      
    }),

    computed: {
    	...mapGetters('login',['getdatosUsuario', 'getEscuela']),

    	filterGrupos(){

    		let grupoFinales = this.grupos

    		// Validamos si hay un plantel y filtramos por ese plantel
    		if( this.plantel ){ grupoFinales = grupoFinales.filter( el => { return el.plantel == this.plantel } )}

    		// Validamos si hay un horario y filtramos por ese horario
    		if( this.horario ){ grupoFinales = grupoFinales.filter( el => { return el.horario == this.horario } )}

    		// Validamos si hay un ciclo y filtramos por ese ciclo
    		if( this.ciclo ){ grupoFinales = grupoFinales.filter( el => { return el.ciclo == this.ciclo } )}

    		// Validamos si hay un nivel y filtramos por ese nivel
    		if( this.nivel ){ grupoFinales = grupoFinales.filter( el => { return el.nivel == this.nivel } )}

        return grupoFinales
      },
    },

    async created () {
      await this.initialize()
      await this.getSalones()
    },


    methods: {

    	getSalones(){
    		this.cargar = true
        this.salones = []
        return this.$http.get('salones.list').then(response=>{
        	this.salones = response.data
        	this.cargar      = false
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })
    	},

      // Obtener todas las sucursales
      initialize() {
        this.cargar  = true
        this.grupos  = []

        return this.$http.get('grupos.habiles').then((response) => {

          // Llenamos la etapa incial
          this.grupos    = response.data.grupos
          this.planteles = response.data.planteles
          this.niveles   = response.data.niveles
          this.ciclos    = response.data.ciclos
          this.horarios  = response.data.horarios
          this.cargar    = false

        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })
      },

      save ( value ){

      	const payload = {
      		id_grupo: value.id_grupo,
      		id_salon: value.id_salon
      	}

      	this.cargar  = true

        return this.$http.post('grupos.update.salon', payload).then((response) => {

          this.initialize()
          this.cargar    = false

        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })


      }
    },
  };
</script>
